<template>
  <div>
    <heads></heads>
    <div class="cen" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="cen_box">
        <div v-for="(item, index) in courses" :key="index">
          <div class="cen_top cen_list" v-if="item.order == 1">
            <router-link :to="{
              path: '/practice/guoxue',
              query: { id: items.id },
            }" v-for="(items, indexs) in item.practice_course" :key="indexs">
              {{ items.name }}
            </router-link>
          </div>
          <div class="cen_center cen_list" v-if="item.order == 2">
            <router-link :to="{
              path: '/practice/nationCulture',
              query: { id: items.id },
            }" v-for="(items, indexs) in item.practice_course" :key="indexs">
              {{ items.name }}
            </router-link>
          </div>
          <div class="cen_bottom cen_list" v-if="item.order == 3">
            <router-link :to="{
              path: '/practice/history',
              query: { id: items.id },
            }" v-for="(items, indexs) in item.practice_course" :key="indexs">
              {{ items.name }}
            </router-link>
          </div>
        </div>

        <div>
          <div class="cen_medicine cen_list">
            <router-link :to="{
              path: '/practice/medicine',
              query: { id: item.id },
            }" v-for="(item, index) in gradeCategorys" :key="index">
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getPracticeCoursesByModular, getPracticeGradeCategorys } from "@api/public";
export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      courses: [],
      gradeCategorys: [],
      backgroud: "",
    };
  },
  mounted: function () {
    let that = this;
    getPracticeCoursesByModular().then(function (res) {
      that.$set(that, "courses", res.data);
    });

    getPracticeGradeCategorys().then(function (res) {
      that.$set(that, "gradeCategorys", res.data.list);
    });
  },
  created: function () {
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
  },
};
</script>

<style scoped>
.cen {
  width: 100%;
  min-width: 1484px;
  height: 1906px;

  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.cen_box {
  width: 1484px;
  height: 1906px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.cen_top {
  padding-top: 298px;
  align-items: center;
}

.cen_center {
  padding-top: 55px;
}

.cen_center.cen_list {
  height: 360px !important;
}

.cen_bottom {
  padding-top: 50px;
}

.cen_medicine {
  padding-top: 120px;
  padding-left: 200px;
  margin: 0 auto;
}

.cen_box a {
  width: 15px;
  font-size: 22px;
  color: #000;
  margin-right: 38px;
  font-family: "kaiti";
  font-weight: bold;
  writing-mode: vertical-lr !important;
}

.cen_box a:hover {
  color: #e2cea0;
}

.cen_list {
  width: 1184px;
  height: 304px;
  margin: 0 auto;
  display: flex;
}

.cen_top>a:nth-child(1) {
  padding-left: 350px;
}

.cen_center>a {
  padding-top: 20px;
  line-height: 1.2;
}

.cen_bottom>a {
  padding-top: 50px;
}

.cen_center>a:nth-child(1) {
  margin-left: 84px;
}

.cen_bottom>a:nth-child(1) {
  margin-left: 415px;
}
</style>
